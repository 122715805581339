<template>
    <div class="base-grid" :class="{ 'base-grid--stretch': stretch }" :style="`--base-grid-columns:${columns}`">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        columns: {
            type: Number,
            default: 3
        },
        stretch: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style src="./BaseGrid.less" lang="less"></style>
